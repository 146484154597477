module.exports = {
  metadata: {
    title: "turingsecure",
    description: "Website created with create-tpws-website",
    author: "https://turingpages.de",
    generator: "turingpages",
  },

  tpws: {
    environments: {
      development: {
        siteUrl: "http://localhost:9000",
      },
      staging: {
        siteUrl: "https://preview.staging.turingsecurecom.pulso-media.de",
      },
      production: {
        siteUrl: "https://turingsecure.com",
      },
    },
    defaultLocale: "en",
    dynamicTemplate: "./src/templates/dynamic-template.js",
    subfont: false,
    allowHtml: true,
  },

  strapi: {
    environments: {
      development: {
        url: "http://localhost:3000",
      },
      staging: {
        url: "https://staging.turingsecurecom.pulso-media.de",
        token:
          "30bdb16ef856be56f4744ae6113aa3b0bc290be34a63b8a41663fefc108c7eeea5eb00b80e29720f92fd76514b8b106e18af9adfb13adb0a7196ec787cd5cda2851d6e56e8042deabef0c9a07f344742318a51ffb92cf15e1e8e7fb018d585d8822bba01776e8946fbbcf6d4e1368f6d76fe6670bc8d8bd8102c0f8307cef93c",
      },
      production: {
        url: "https://turingsecurecom.pulso-media.de",
        token:
          "30bdb16ef856be56f4744ae6113aa3b0bc290be34a63b8a41663fefc108c7eeea5eb00b80e29720f92fd76514b8b106e18af9adfb13adb0a7196ec787cd5cda2851d6e56e8042deabef0c9a07f344742318a51ffb92cf15e1e8e7fb018d585d8822bba01776e8946fbbcf6d4e1368f6d76fe6670bc8d8bd8102c0f8307cef93c",
      },
    },
    collectionTypes: [
      "security-page",
      "certificate",
      "certificate-template",
      "issue-template",
      "issue-tag",
      "issue-category",
      "issue-text",
      "pricing-attribute",
      "pricing-section",
      "pricing-template",
      "pricing-tier",
    ],
    singleTypes: [],
  },

  turingsecureApp: {
    environments: {
      staging: {
        certificateWebhook:
          "https://padj7scgkk.execute-api.eu-central-1.amazonaws.com/development/certificate/build",
      },
      production: {
        certificateWebhook:
          "https://mfu91lt2t8.execute-api.eu-central-1.amazonaws.com/production/certificate/build",
      },
    },
  },
};
