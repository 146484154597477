import "./src/styles/global.css";

import * as React from "react";
import { StoreProvider } from "./src/context/store-context";
import tpwsConfig from "./tpws.config.js";
//import Layout from "./src/components/layout"

export const wrapRootElement = ({ element }) => {
  if (!tpwsConfig?.shopify) {
    return element;
  }
  return <StoreProvider>{element}</StoreProvider>;
};

/*
export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};
 */
